.sidebar_wrap ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
.pickup_order_heading{
    width: 100% !important; 
    height: 100% !important; 
    background: #012269 !important; 
    border-top-left-radius: 13px !important;
    border-top-right-radius: 13px !important;
    color: white !important;
    padding: 10px !important;
}
.pickup_order_heading2{
    width: 100% !important; 
    height: 100% !important; 
    background: #012269 !important; 
    border-top-left-radius: 13px !important;
    border-top-right-radius: 13px !important;
    color: white !important;
    padding: 30px !important;
}
.pickup_detail_order{
    color: white !important;

    font-size: 20px !important;
    font-family: Arial !important;
    font-weight: 400 !important;
    word-wrap: break-word !important;
}
.pickup_detail_order2{
    color: white !important;

    font-size: 34px !important;
    font-family: Arial !important;
    font-weight: 400 !important;
    word-wrap: break-word !important;
}
.pickup_detail_code{
    color: black !important;
    font-size: 20px !important;
    font-family: Arial !important;
    font-weight: 400 !important;
    word-wrap: break-word !important
}
.pickup_book_by{
    color: black !important; 
    font-size: 18px !important; 
    font-family: Arial !important;  
    font-weight: 400 !important; 
    word-wrap: break-word !important;
    margin-top: 15px !important
}
.pickup_book_name{
    color: black !important; 
    font-size: 20px !important; 
    font-family: Arial !important;  
    font-weight: 400 !important; 
    word-wrap: break-word !important;
    margin-top: 10px !important
}
.pickup_book_email{
    color: black !important; 
    font-size: 14px !important; 
    font-family: Arial !important;  
    font-weight: 400 !important; 
    word-wrap: break-word !important;
    margin-bottom: 10px !important;
}
.pickup_detail_box{
    background: white !important;
    border: 1px solid #98a7b7 !important;
    margin:20px !important;
    border-radius:10px !important
}
.pickup_label{
    color: #002770 !important;
    font-size: 14px !important;
    font-weight: 700 !important;
}
.pickup_label2{
    color: white !important;
    font-size: 14px !important;
    font-weight: 700 !important;
}
