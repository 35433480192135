* { 
	margin: 0; 
	padding: 0
} 

html { 
	height: 100%
} 

h2{ 
	color: #012267; 
} 
#form { 
	text-align: center; 
	position: relative; 
	
} 






#form .previous-step, .next-step { 
	width: 100px; 
	font-weight: bold; 
	color: white; 
	border: 0 none; 
	border-radius: 0px; 
	cursor: pointer; 
	padding: 10px 5px; 
	margin: 10px 5px 10px 0px; 
	float: right
} 

.form, .previous-step { 
	background: #616161; 
} 

.form, .next-step { 
	background: #012267; 
} 

#form .previous-step:hover, 
#form .previous-step:focus { 
	background-color: #000000
} 

#form .next-step:hover, 
#form .next-step:focus { 
	background-color: #012267
} 

.text { 
	color: #012267; 
	font-weight: 100
} 

#progressbar { 
	margin-bottom: 30px; 
	overflow: hidden; 
	color: lightgrey 
} 

#progressbar .active { 
	color: #012267
} 

#progressbar li { 
	list-style-type: none; 
	font-size: 10px; 
	width: 25%; 
	float: left; 
	position: relative; 
	font-weight: 400
} 

#progressbar #step1:before { 
	content: "01"
} 

#progressbar #step2:before { 
	content: "02"
} 

#progressbar #step3:before { 
	content: "03"
} 

#progressbar #step4:before { 
	content: "04"
} 

#progressbar li:before { 
	width: 30px; 
	height: 30px; 
	line-height: 25px; 
	display: block; 
	font-size: 15px; 
	color: #ffffff; 
	background: lightgray; 
	border-radius: 50%; 
	margin: 0 auto 10px auto; 
	padding: 2px
} 

#progressbar li:after { 
	content: ''; 
	width: 100%; 
	height: 2px; 
	background: lightgray; 
	position: absolute; 
	left: 0; 
	top: 15px; 
	z-index: -1
} 

#progressbar li.active:before, 
#progressbar li.active:after { 
	background: #012267
} 

.progress { 
	height: 10px
} 

.progress-bar { 
	background-color: #012267
}
