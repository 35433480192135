.sidebar_wrap ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
.card_wrap{
    border-radius: 10px;
    border: 0.5px #EEEEEE solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 15px;
    margin: 0px 10px 40px 10px
    /* margin: 0 10px 40px 10px; */
    
}
.card_icon{
    background: rgba(132.83, 157.45, 34.38, 0.10);
    border-radius: 25px;
    width: 50px;
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.card_cont{}
.card_head{
    color: #012267;
    font-size: 17px;
    font-family: Arial;
    font-weight: bold;
    word-wrap: break-word;
    margin-bottom: 0;
    
}
.card_text{
    color: #012267;
    font-size: 12px;
    font-family: Arial;
    font-weight: bold;
    word-wrap: break-word;
    margin: 0;
}
.qr_roles_wrap{
    margin-bottom: 40px;
}
.card_box{
    background: white;
    border-radius: 10px;
    border: 0.5px #EEEEEE solid;
    padding: 20px;
    height: 100%;
}
.card_box h2 {
    color: black;
    font-size: 20px;
    font-family: Arial;
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: 0;
} 
.card_box p{
    color: #999999;
    font-size: 12px;
    font-family: Arial;
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: 20px;
}
.tables_wrap th {
    color: #222222;
    font-size: 10px;
    font-family: Arial;
    font-weight: 700;
    text-transform: uppercase;
    word-wrap: break-word;
    border-bottom: none;
    padding: 0.5rem 20px;
}
.tables_wrap th:nth-child(1){
    padding-left: 0;
}
.tables_wrap td{
    color: #222222;
font-size: 12px;
font-family: Arial;
font-weight: 400;
word-wrap: break-word;
padding: 20px;
}
.tables_wrap td:nth-child(1){
    padding-left: 0;
}
.tables_wrap td:nth-last-child(1){
    padding-right: 0;
    text-align: right;
}
.tables_wrap.tables_role td:nth-last-child(1){
    text-align: left;
}
.tables_role{}
.tables_wrap{}
.tab_body_wrap_role{}
.tab_body_wrap_role h2 {
    color: #222222;
    font-size: 10px;
    font-family: Arial;
    font-weight: 700;
    text-transform: uppercase;
    word-wrap: break-word;
    padding: 0.5rem;
}
.tab_body_wrap_role p{
    color: #222222;
    font-size: 12px;
    font-family: Arial;
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: 0;
    padding: 0.5rem;
}
.delete_modal h3{
    color: black;
    font-size: 16px;
    font-family: Arial;
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: 0;
    text-align: center;
    padding: 20px 0;
    color: #B60000;
}





@media (max-width: 1399px){
    .tables_wrap td{
        color: #222222;
       font-size: 11px;
}
}

@media (max-width: 1200px){
    
    .card_icon{
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
    .card_icon img{
        max-width: 70%;
    }
    .card_wrap{
        margin: 0 0px 20px;
        padding: 10px;
    }
    
}