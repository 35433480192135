.sidebar_wrap ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.table_pad {
  padding: 0px 300px;
}
.shopping_cart {
  font-weight: 700;
}
@media (max-width: 1024px) {
  .table_pad {
    padding: 0px 100px;
  }
  .shopping_cart,
  .trans_complete {
    margin-top: 90px;
  }
}
@media (max-width: 991px) {
  .table_pad {
    padding: 0px 150px;
  }
  .shopping_cart,
  .trans_complete {
    margin-top: 90px;
  }
}
@media (max-width: 820px) {
  .table_pad {
    padding: 0px 200px;
  }
  .shopping_cart,
  .trans_complete {
    margin-top: 90px;
  }
}
@media (max-width: 769px) {
  .table_pad {
    padding: 0px 100px;
  }
  .shopping_cart,
  .trans_complete {
    margin-top: 90px;
  }
}
@media (max-width: 575px) {
  .table_pad {
    padding: 0px 50px;
  }
  .shopping_cart,
  .trans_complete {
    margin-top: 90px;
  }
}
@media (max-width: 433px) {
  .table_pad {
    padding: 0px 30px;
  }
  .shopping_cart,
  .trans_complete {
    margin-top: 90px;
  }
}
@media (max-width: 376px) {
  .table_pad {
    padding: 0px 20px;
  }
  .shopping_cart,
  .trans_complete {
    margin-top: 90px;
  }
}
@media (max-width: 280px) {
  .table_pad {
    padding: 0px 5px;
  }
  .shopping_cart,
  .trans_complete {
    margin-top: 90px;
  }
}
