/* my css */
.dashboard-content-wrap {
  display: flex;
  padding: 5px;
  gap: 5px;
  color: #012269;
}

.container-det {
  width: 100%;
  height: 100%;
  border-radius: 18px;
  background-color: #eff1ff;
  gap: 5px;
}

.text-wrap {
  display: flex;
  align-items: flex-start;
  height: 30%;
  font-size: 10px;
  /* border: 1px solid black; */
  border-radius: 5px;
  padding: 5px;
  background-color: #012269;
  color: white;
}

.container-wrap {
  width: 98%;
  height: 100%;
  border-radius: 10px;
  background-color: #fff;
  margin-left: 10px;
  margin-bottom: 20px;
  padding: 20px;
  overflow-x: auto;
  white-space: nowrap;
}

.container-fluided {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.container-det {
  width: 100%;
  height: 100%;
  border-radius: 18px;
  background-color: #eff1ff;
}

.container-fluid-text {
  margin-top: 5px;
  text-align: center;
  font-size: 8px;
  font-weight: bold;
}

.container-fluid-text h5,
.container-text h5 {
  font-weight: 500;
}

.container-fluid-text h6,
.container-fluid-text p {
  font-weight: 500;
}

.dropbox-wrap {
  margin-bottom: 4%;
}

#dropdownMenuButton1 {
  width: 100%;
  text-align: left;
  background-color: #212c63;
}

table td,
table th {
  border: none;
}

.container-data {
  border: 1px solid black;
  width: 600px;
  height: 200px;
}

.container-upload {
  border-radius: 10px;
  height: 350px;
  background-color: #fff;
  padding: 30px;
}

@media (max-width: 991px) {
  .container-wrap {
    width: auto;
    height: 361px;
    border-radius: 10px;
    background-color: #fff;
    margin-left: 10px;
    margin-bottom: 20px;
    padding: 20px;
    overflow-x: auto;
    white-space: nowrap;
  }

  .container-fluided {
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    gap: 15px;
    margin-left: 35px;
  }
}

@media (max-width: 575px) {
  #qr-codepic {
    margin-left: 70px;
  }

  .container-wrap {
    width: 95%;
    height: 361px;
    border-radius: 10px;
    background-color: #fff;
    margin-left: 10px;
    margin-bottom: 20px;
    padding: 20px;
    overflow-x: auto;
    white-space: nowrap;
  }

  .container-fluided {
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 15px;
    margin-left: 8px;
  }
}

/* ///////-----Upload and Uploading file----////// */

.containerr-main {
  margin-left: 220px;
  margin-top: 50px;
  margin-bottom: 20px;
  /* width: auto; */
  width: 567px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}

.image-containerr {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-dataa {
  /* width: 510px; */
  width: auto;
  height: auto;
}

.button-container {
  margin-top: 30px;
  margin-bottom: 10px;
}
.slick-prev:before,
.slick-next:before {
  color: rgba(1, 34, 105, 1) !important; /* Change to the desired color */
}
.btn-prim {
  margin-top: 10px;
}

.progress-barr {
  margin-right: 85px;
}

.counter-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-container h1,
.button-container h3 {
  color: #012267;
}

.button-container h1 {
  font-weight: 200;
}

.checkout-scr {
  border-radius: 10px;
  margin-left: 3%;
}

.container-box {
  height: 100%;
  width: 70%;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 10px;
}

.pay-amount {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #012267;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.pay-amount p {
  font-size: 10px;
}

.pay-amount p,
.pay-amount h1 {
  margin: 0;
}

.card-info {
  width: 270px;
  margin-left: 20px;
  margin-top: 30px;
  padding: 10px;
}

.card-info p,
.card-info form {
  color: #012267;
  font-weight: 400;
}

.card-info label,
.card-info input {
  margin: 0;
  color: #012267;
}

.payment-imagee img {
  width: 100%;
}

@media (max-width: 1024px) {
  .containerr-main {
    width: 80%;
    margin-left: 0;
    margin-top: 10px;
    margin-right: 0;
  }

  .container-dataa {
    width: 100%;
  }

  .container-box {
    width: 100%;
    margin-left: 0;
  }

  .card-info {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
    padding: 5px;
  }

  .button-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .image-containerr img {
    width: 250px;
  }
}

@media (max-width: 992px) {
  .progress-barr {
    margin-left: 80px;
  }

  .containerr-main {
    width: 80%;
    margin-left: 70px;
    margin-top: 10px;
    margin-right: 0;
  }

  .container-dataa {
    width: 100%;
  }

  .container-box {
    width: 100%;
    margin-left: 0;
  }

  .card-info {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
    padding: 5px;
  }

  .button-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .image-containerr img {
    width: 250px;
  }
}

@media (max-width: 768px) {
  .progress-barr {
    margin-left: 80px;
  }

  .containerr-main {
    width: auto;
    margin-left: 0;
    margin-top: 10px;
    margin-right: 0;
  }

  .container-dataa {
    width: 100%;
  }

  .container-box {
    width: 100%;
    margin-left: 0;
  }

  .card-info {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
    padding: 5px;
  }

  .button-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .image-containerr img {
    width: 250px;
  }
}

/* ///////-----MyComponent file----////// */

.main-containerbox {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
}

.data-box {
  padding: 10px;
}

.booked-container-details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
}

.top-heading h5 {
  font-weight: 600;
}

.middle-header p {
  color: #74829c !important;
  margin-top: 0.75rem !important;
}

.booked-container {
  border-radius: 16.47px;
  border: 2px solid #eee;
  /* background: #f9fafb; */
  background: #fff;
}

.booked-data {
  width: 180px;
  text-align: center;
  padding: 20px;
}

.booked-data img {
  width: 135px;
  height: 135px;
  /* background-color: gray; */
  border-radius: 5px;
}

.product-images img {
  width: 140px;
  height: 100px;
  /* background-color: gray; */
  border-radius: 5px;
  /* object-fit: cover; */
}
.product-count {
  width: 100%;
  background: rgb(206, 205, 205);
  border-radius: 5px;
  margin-top: 15px;
}
.product-count p {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}
.product-name {
  width: auto;
}
.product-name h5 {
  font-size: 12px;
  line-height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 12px;
}
.product-cart img {
  width: 20px !important;
  height: 20px !important;
  background-color: #fff !important;
  border-radius: 5px;
}
.product-cart p {
  font-size: 12px;
}
.product-cart-slider {
  position: absolute;
  top: 232px;
  left: 55px;
}
.product-cart-slider img {
  width: 15px !important;
  height: 15px !important;

  border-radius: 5px;
}
.product-cart-slider p {
  font-size: 10px;
}
.product-images-locker img {
  width: 121px;
  height: 130px;
  position: absolute;
  top: 100px;
  left: 14px;
  border: 1px solid black;
  border-radius: 10px;
  object-fit: cover;
}

.product-images-locker-stringee img {
  width: 98px;
  height: 115px;
  position: absolute;
  top: 90px;
  left: 15px;
  border: 1px solid black;
  border-radius: 10px;
  object-fit: cover;
}

.product-images-locker-short img {
  width: 108px;
  height: 69px;
  position: absolute;
  top: 44px;
  left: 12px;
  border: 1px solid black;
  border-radius: 10px;
  /* object-fit: cover; */
}

.product-images-locker-short-stringee img {
  width: 86px;
  height: 59px;
  position: absolute;
  top: 43px;
  left: 15px;
  border: 1px solid black;
  border-radius: 10px;
  /* object-fit: cover; */
}

.product-images-locker-short-2 img {
  width: 108px;
  height: 73px;
  position: absolute;
  top: 212px;
  left: 12px;
  border: 1px solid black;
  border-radius: 10px;
  /* object-fit: cover; */
}

.product-images-locker-short-stringee-2 img {
  width: 88px;
  height: 63px;
  position: absolute;
  top: 188px;
  left: 13px;
  border: 1px solid black;
  border-radius: 10px;
  /* object-fit: cover; */
}
.product-name-locker {
  width: 115px;
  height: auto;
  position: absolute;
  top: 240px;
  left: 18px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.product-name-locker-stringee {
  width: 115px;
  height: auto;
  position: absolute;
  top: 216px;
  left: 11px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.product-name-locker-short {
  width: 105px;
  position: absolute;
  top: 120px;
  left: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.product-name-locker-stringee-short {
  width: 105px;
  position: absolute;
  top: 108px;
  left: 9px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.product-name-locker-short-2 {
  width: 105px;
  position: absolute;
  top: 290px;
  left: 18px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.product-name-locker-stringee-short-2 {
  width: 105px;
  position: absolute;
  top: 256px;
  left: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.product-name-locker h6 {
  font-size: 12px;
  font-weight: 500;
}

.product-name-locker-stringee h6 {
  font-size: 12px;
  font-weight: 500;
}
.product-name-locker-short h6 {
  font-size: 10px;
  font-weight: 700;
}
.product-name-locker-stringee-short h6 {
  font-size: 10px;
  font-weight: 700;
}
.product-name-locker-short-2 h6 {
  font-size: 10px;
  font-weight: 700;
}
.product-name-locker-stringee-short-2 h6 {
  font-size: 10px;
  font-weight: 700;
}
.locker-num {
  position: absolute;
  top: 28px;
  left: 114px;
  font-weight: 700;
}

.stringee-locker-num {
  position: absolute;
  top: 28px;
  left: 100px;
  font-weight: 700;
}

.locker-num1 {
  position: absolute;
  top: 15px;
  left: 105px;
  font-weight: 700;
}
.stringee-locker-num1 {
  position: absolute;
  top: 14px;
  left: 93px;
  font-weight: 700;
}
.locker-num2 {
  position: absolute;
  top: 184px;
  left: 101px;
  font-weight: 700;
}
.stringee-locker-num2 {
  position: absolute;
  top: 159px;
  left: 88px;
  font-weight: 700;
}
.selected-locker-div-container {
  background: rgb(221, 221, 221);
  background: linear-gradient(
    115deg,
    rgba(238, 238, 238, 1) 55%,
    rgba(221, 221, 221, 1) 45%
  ) !important;
  width: 180px;
  max-width: 180px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  padding: 1rem;
  width: 1px;
}

::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.2);
}

.selected-locker-div {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* .slick-current > div > .locker-div-container {
  background: rgb(221, 221, 221);
  background: linear-gradient(114deg, rgba(238, 238, 238, 1) 50%, rgba(221, 221, 221, 1) 45%) !important;
  max-width: 155px;
} */

.locker-div-container {
  background: rgb(221, 221, 221);
  background: linear-gradient(
    115deg,
    rgba(238, 238, 238, 1) 50%,
    rgba(221, 221, 221, 1) 45%
  ) !important;
  max-width: 155px;
}

.locker-div {
  height: 300px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product-images-locker-slider img {
  width: 125px !important;
  height: 115px;
  position: absolute;
  top: 96px;
  left: 17px;
  border: 1px solid black;
  border-radius: 10px;
  object-fit: cover;
}
.product-name-locker-slider {
  width: 115px;
  height: auto;
  position: absolute;
  top: 215px;
  left: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.product-name-locker-slider h6 {
  font-size: 12px;
  font-weight: 500;
}
.Owl-top {
  margin-top: 100px;
}
.cart-button {
  position: absolute;
  top: 255px;
  left: 18px;
}
/* .owl-dots {
  display: none;
} */
.back-butt {
  margin-left: 90%;
  width: 100px;
}

@media (max-width: 1024px) {
  .booked-container-details {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
    margin-bottom: 10px;
  }

  .booked-container {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top: 15px;
    margin-left: 35px;
  }

  .back-butt {
    margin-left: 80%;
    width: 100px;
  }
}

@media (max-width: 992px) {
  .booked-container-details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 10px;
    margin-bottom: 10px;
  }

  .booked-container {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top: 15px;
    margin-left: 35px;
  }
}

@media (max-width: 830px) {
  .booked-container-details {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
    margin-bottom: 10px;
  }

  .booked-container {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top: 15px;
    margin-left: 35px;
  }

  .back-butt {
    margin-left: 80%;
    width: 100px;
  }
}

@media (max-width: 495px) {
  .booked-container-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
    margin-bottom: 10px;
  }

  .booked-container {
    align-items: flex-start;
    flex-direction: column;
    margin-top: 15px;
    margin-left: 30px;
  }

  .back-butt {
    margin-left: 60%;
    width: 100px;
  }

  .container-fluided {
    justify-content: space-between;
  }
}

/* ///////-----Qrscan file----////// */

.main-body {
  width: 50%;
  height: auto;
  margin-left: 25%;
  margin-top: 8%;
  border-radius: 10px;
  background-color: #fff;
}

.qr-heading {
  background-color: #012269;
  padding: 10px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
}

.body-container {
  padding: 30px;
  margin-bottom: 40px;
}

.body-container p {
  color: #012269;
  margin-left: 10px;
}

@media (max-width: 992px) {
  .main-body {
    width: 80%;
    height: auto;
    margin-left: 10%;
    margin-top: 10%;
    border-radius: 10px;
    background-color: #fff;
  }
}

@media (max-width: 830px) {
  .main-body {
    width: 70%;
    height: auto;
    margin-left: 18%;
    margin-top: 10%;
    border-radius: 10px;
    background-color: #fff;
  }
}

@media (max-width: 475px) {
  .main-body {
    width: 100%;
    height: auto;
    margin-left: 0.3%;
    margin-top: 100px;
    border-radius: 10px;
    background-color: #fff;
  }
}

/*//////////////---- Scan Container file----//////// */

.scan-container {
  background-color: #fff;
  border-radius: 10px;
  margin-left: 120px;
}

.scan-body {
  padding: 20px;
  text-align: center;
}

.scan-body p {
  margin: 0;
  color: #012269;
  font-weight: 500;
}

.scan-imge2 img {
  margin-top: 100px;
}

@media (max-width: 1024px) {
  .scan-container {
    max-width: 100%;
    margin-left: 0;
    margin-right: 50px;
  }

  .scan-body img {
    width: 120px;
  }

  .scan-imge2 img {
    width: 100%;
    margin-left: 20px;
  }
}

@media (max-width: 992px) {
  .scan-container {
    max-width: 100%;
    margin-left: 30px;
    margin-right: 20px;
    margin-left: 0;
    margin-right: 50px;
  }

  .scan-body img {
    width: 120px;
  }

  .scan-imge2 img {
    width: 100%;
    margin-left: 20px;
  }
}

@media (max-width: 768px) {
  .scan-container {
    max-width: 100%;
    margin-left: 30px;
    margin-right: 0;

    margin-left: 0;
    margin-right: 50px;
  }

  .scan-body img {
    width: 120px;
  }

  .scan-imge2 img {
    width: 100%;
    margin-left: 30px;
    margin-left: 0;
  }
}

/* ////////////-------ScanDetail file------////////// */

.container-detail-body {
  width: auto;
  height: auto;
  background-color: #fff;
  padding: 30px;
  margin: 0 10px 10px 10px;
}

.scan-detail-image {
  margin-top: 80px;
}

.scan-selected-imges {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.scan-selected-imges img {
  width: 80px;
  height: 80px;
  background-color: gray;
}

@media (max-width: 992px) {
  .scan-detail-image img {
    width: 130%;
    margin-left: 30px;
  }
}

@media (max-width: 768px) {
  .scan-detail-image img {
    width: 80%;
    margin-left: 30px;
  }
}

/*////////////-------- VideoChat file---------/////////// */

.container-detail-video {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  margin: 0 10px 10px 10px;
}

.video-slider,
.image-slider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-slider video,
.image-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scanner-button-body {
  width: 100%;
  position: absolute;
  bottom: 2%;
  padding: 20px;
}

.scanner-img-body {
  display: flex;
  gap: 30px;
}

.start-to-scan img {
  width: 20%;
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
}

.start-to-scan img,
.test-wifi img {
  width: 60%;
  border-radius: 10px;
}

.start-to-scan p,
.test-wifi p {
  margin-left: 20%;
  color: #012269;
  margin-top: 10px;
  color: #fff;
}

#scanner-buttons {
  width: 50%;
  margin-top: 1%;
  margin-left: 50%;
}

.video-slider img.play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  cursor: pointer;
}

.video-slider img.video-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 9999;
}

video::-webkit-media-controls {
  display: none !important;
}

@media (max-width: 1024px) {
  .video-slider video,
  .image-slider img {
    width: 100%;
  }

  #scanner-buttons {
    width: 100%;
    margin-left: 0;
  }
  .product-name-locker {
    width: 70px;
    position: absolute;
    top: 46px;
    left: 18px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker h6 {
    font-size: 9px;
    font-weight: 700;
  }
}

@media (max-width: 992px) {
  .video-slider,
  .scanner-img-body {
    width: 100%;
    border-radius: 10px;
    margin-right: 10px;
  }

  .scanner-img-body {
    justify-content: center;
    margin-top: 20px;
  }

  .scanner-img-body img {
    width: 200px;
    margin-top: 20px;
  }

  .video-slider video,
  .image-slider img {
    width: 100%;
  }

  #scanner-buttons {
    width: 100%;
    margin-left: 0;
  }

  .product-images-locker img {
    width: 80px;
    height: 100px;
    position: absolute;
    top: 59px;
    left: 10px;
    border-radius: 3px;
    object-fit: cover;
  }
  .product-name-locker {
    width: 66px;
    position: absolute;
    top: 180px;
    left: 19px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker h6 {
    font-size: 9px;
    font-weight: 300;
  }
  .product-images-locker-short img {
    width: 64px !important;
    height: 43px;
    position: absolute;
    top: 29px;
    left: 10px;
    border-radius: 3px;
  }
  .product-name-locker-short {
    width: 70px;
    position: absolute;
    top: 78px;
    left: 21px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker-short h6 {
    font-size: 7px;
    font-weight: 700;
  }
  .product-images-locker-short-2 img {
    width: 63px !important;
    height: 44px;
    position: absolute;
    top: 144px;
    left: 10px;
    border-radius: 3px;
  }
  .product-name-locker-short-2 {
    width: 70px;
    position: absolute;
    top: 193px;
    left: 19px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker-short-2 h6 {
    font-size: 7px;
    font-weight: 700;
  }
  .locker-num {
    position: absolute;
    top: 14px;
    left: 80px;
    font-weight: 700;
    font-size: 10px;
  }
  .locker-num1 {
    position: absolute;
    top: 10px;
    left: 80px;
    font-weight: 700;
    font-size: 9px;
  }
  .locker-num2 {
    position: absolute;
    top: 123px;
    left: 80px;
    font-weight: 700;
    font-size: 9px;
  }
  .product-images-locker-slider img {
    width: 164px;
    height: 175px;
    top: 132px;
    left: 28px;
  }
  .Owl-top {
    margin-top: 150px;
  }
  .product-name-locker-slider {
    width: 180px;
    height: auto;
    top: 310px;
    left: 20px;
  }
  .product-name-locker-slider h6 {
    font-size: 12px;
    font-weight: 500;
  }
  .cart-button {
    position: absolute;
    top: 360px;
    left: 50px;
  }
  .product-cart-slider {
    position: absolute;
    top: 335px;
    left: 85px;
  }
  .user-video-screens {
    width: 220px;
    height: 250px;
    position: relative;
    margin-top: 0%;
  }
}
@media (max-width: 820px) {
  .product-images-locker img {
    width: 75px;
    height: 95px;
    position: absolute;
    top: 60px;
    left: 15px;
    border-radius: 3px;
    object-fit: cover;
  }
  .product-name-locker {
    width: 75px;
    position: absolute;
    top: 162px;
    left: 14px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker h6 {
    font-size: 8px;
    font-weight: 300;
  }
  .product-images-locker-short img {
    width: 69px;
    height: 50px;
    position: absolute;
    top: 30px;
    left: 13px;
    border-radius: 3px;
  }
  .product-name-locker-short {
    width: 75px;
    position: absolute;
    top: 84px;
    left: 10px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker-short h6 {
    font-size: 8px;
    font-weight: 700;
  }
  .product-images-locker-short-2 img {
    width: 71px;
    height: 50px;
    position: absolute;
    top: 151px;
    left: 13px;
    border-radius: 3px;
  }
  .product-name-locker-short-2 {
    width: 75px;
    position: absolute;
    top: 205px;
    left: 10px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker-short-2 h6 {
    font-size: 8px;
    font-weight: 700;
  }
  .locker-num {
    position: absolute;
    top: 15px;
    left: 75px;
    font-weight: 700;
    font-size: 10px;
  }
  .locker-num1 {
    position: absolute;
    top: 10px;
    left: 75px;
    font-weight: 700;
    font-size: 9px;
  }
  .locker-num2 {
    position: absolute;
    top: 132px;
    left: 75px;
    font-weight: 700;
    font-size: 9px;
  }
  .Owl-top {
    margin-top: 100px;
  }
}

@media (max-width: 769px) {
  .video-slider,
  .scanner-img-body {
    width: 100%;
    border-radius: 10px;
    margin-right: 10px;
  }

  .scanner-img-body {
    justify-content: center;
    margin-top: 20px;
  }

  .scanner-img-body img {
    width: 200px;
    margin-top: 20px;
  }

  .video-slider video,
  .image-slider img {
    width: 100%;
  }

  #scanner-buttons {
    width: 100%;
    margin-left: 0;
  }
  .lockers-short img {
    width: 98px !important;
    margin-left: 7px;
  }
  .product-images-locker img {
    width: 80px;
    height: 100px;
    position: absolute;
    top: 75px;
    left: 15px;
    border-radius: 3px;
    object-fit: cover;
  }
  .product-name-locker {
    width: 66px;
    position: absolute;
    top: 180px;
    left: 19px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker h6 {
    font-size: 9px;
    font-weight: 300;
  }
  .product-images-locker-short img {
    width: 76px !important;
    height: 59px;
    position: absolute;
    top: 30px;
    left: 11px;
    border-radius: 3px;
  }
  .product-name-locker-short {
    width: 70px;
    position: absolute;
    top: 92px;
    left: 18px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker-short h6 {
    font-size: 7px;
    font-weight: 700;
  }
  .product-images-locker-short-2 img {
    width: 75px !important;
    height: 60px;
    position: absolute;
    top: 160px;
    left: 12px;
    border-radius: 3px;
  }
  .product-name-locker-short-2 {
    width: 70px;
    position: absolute;
    top: 223px;
    left: 17px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker-short-2 h6 {
    font-size: 7px;
    font-weight: 700;
  }
  .locker-num {
    position: absolute;
    top: 14px;
    left: 80px;
    font-weight: 700;
    font-size: 10px;
  }
  .locker-num1 {
    position: absolute;
    top: 10px;
    left: 80px;
    font-weight: 700;
    font-size: 9px;
  }
  .locker-num2 {
    position: absolute;
    top: 140px;
    left: 80px;
    font-weight: 700;
    font-size: 9px;
  }
  .product-images-locker-slider img {
    width: 164px;
    height: 175px;
    top: 132px;
    left: 28px;
  }
  .Owl-top {
    margin-top: 200px;
  }
  .product-name-locker-slider {
    width: 180px;
    height: auto;
    top: 310px;
    left: 20px;
  }
  .product-name-locker-slider h6 {
    font-size: 12px;
    font-weight: 500;
  }
  .cart-button {
    position: absolute;
    top: 360px;
    left: 50px;
  }
  .product-cart-slider {
    position: absolute;
    top: 335px;
    left: 85px;
  }
  .user-video-screens {
    width: 187px !important;
    height: 198px !important;
    margin-top: 3%;
    margin-right: 35px !important;
  }
}
@media (max-width: 575px) {
  .video-slider,
  .scanner-img-body {
    width: 100%;
    border-radius: 10px;
    margin-right: 10px;
  }

  .scanner-img-body {
    justify-content: center;
    margin-top: 20px;
  }

  .scanner-img-body img {
    width: 200px;
    margin-top: 20px;
  }

  .video-slider video,
  .image-slider img {
    width: 100%;
  }

  #scanner-buttons {
    width: 100%;
    margin-left: 0;
  }
  .lockers-short img {
    width: 67px !important;
  }
  .product-images-locker img {
    width: 58px;
    height: 71px;
    position: absolute;
    top: 48px;
    left: 11px;
    border-radius: 3px;
    object-fit: cover;
  }
  .product-name-locker {
    width: 66px;
    position: absolute;
    top: 130px;
    left: 8px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker h6 {
    font-size: 6px;
    font-weight: 300;
  }
  .product-images-locker-short img {
    width: 53px !important;
    height: 39px;
    position: absolute;
    top: 22px;
    left: 7px;
    border-radius: 3px;
  }
  .product-name-locker-short {
    width: 70px;
    position: absolute;
    top: 63px;
    left: 6px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker-short h6 {
    font-size: 7px;
    font-weight: 700;
  }
  .product-images-locker-short-2 img {
    width: 53px !important;
    height: 40px;
    position: absolute;
    top: 116px;
    left: 7px;
    border-radius: 3px;
  }
  .product-name-locker-short-2 {
    width: 70px;
    position: absolute;
    top: 158px;
    left: 8px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker-short-2 h6 {
    font-size: 7px;
    font-weight: 700;
  }
  .locker-num {
    position: absolute;
    top: 10px;
    left: 57px;
    font-weight: 700;
    font-size: 10px;
  }
  .locker-num1 {
    position: absolute;
    top: 8px;
    left: 63px;
    font-weight: 700;
    font-size: 9px;
  }
  .locker-num2 {
    position: absolute;
    top: 100px;
    left: 60px;
    font-weight: 700;
    font-size: 9px;
  }
  .product-images-locker-slider img {
    width: 164px;
    height: 175px;
    top: 132px;
    left: 28px;
  }
  .Owl-top {
    margin-top: 150px;
  }
  .product-name-locker-slider {
    width: 180px;
    height: auto;
    top: 310px;
    left: 20px;
  }
  .product-name-locker-slider h6 {
    font-size: 12px;
    font-weight: 500;
  }
  .cart-button {
    position: absolute;
    top: 360px;
    left: 50px;
  }
  .product-cart-slider {
    position: absolute;
    top: 335px;
    left: 85px;
  }
}

@media (max-width: 441px) {
  .lockers-short img {
    width: 45px !important;
    margin-left: 5px;
  }
  .product-images-locker img {
    width: 33px;
    height: 42px;
    position: absolute;
    top: 32px;
    left: 10px;
    border-radius: 3px;
    object-fit: cover;
  }
  .product-name-locker {
    width: 40px;
    position: absolute;
    top: 77px;
    left: 7px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker h6 {
    font-size: 5px;
    font-weight: 300;
  }
  .product-images-locker-short img {
    width: 30px !important;
    height: 22px;
    position: absolute;
    top: 17px;
    left: 8px;
    border-radius: 3px;
  }
  .product-name-locker-short {
    width: 60px;
    position: absolute;
    top: 40px;
    left: 0px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker-short h6 {
    font-size: 5px;
    font-weight: 300;
  }
  .product-images-locker-short-2 img {
    width: 29px !important;
    height: 23px;
    position: absolute;
    top: 78px;
    left: 9px;
    border-radius: 3px;
  }
  .product-name-locker-short-2 {
    width: 60px;
    position: absolute;
    top: 102px;
    left: 0px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker-short-2 h6 {
    font-size: 5px;
    font-weight: 300;
  }
  .locker-num {
    position: absolute;
    top: 5px;
    left: 35px;
    font-weight: 700;
    font-size: 10px;
  }
  .locker-num1 {
    position: absolute;
    top: 4px;
    left: 35px;
    font-weight: 700;
    font-size: 8px;
  }
  .locker-num2 {
    position: absolute;
    top: 66px;
    left: 34px;
    font-weight: 700;
    font-size: 8px;
  }
  .Owl-top {
    margin-top: 100px;
  }
}
@media (max-width: 376px) {
  .lockers-short img {
    width: 44px;
  }
  .product-images-locker img {
    width: 36px;
    height: 41px;
    position: absolute;
    top: 32px;
    left: 5px;
    border-radius: 3px;
    object-fit: cover;
  }
  .product-name-locker {
    width: 41px;
    position: absolute;
    top: 77px;
    left: 4px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker h6 {
    font-size: 4px;
    font-weight: 300;
  }
  .product-images-locker-short img {
    width: 25px !important;
    height: 17px;
    position: absolute;
    top: 16px;
    left: 11px;
    border-radius: 3px;
  }
  .product-name-locker-short {
    width: 60px;
    position: absolute;
    top: 35px;
    left: -3px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker-short h6 {
    font-size: 5px;
    font-weight: 700;
  }
  .product-images-locker-short-2 img {
    width: 27px;
    height: 18px;
    position: absolute;
    top: 78px;
    left: 8px;
    border-radius: 3px;
  }
  .product-name-locker-short-2 {
    width: 60px;
    position: absolute;
    top: 99px;
    left: -3px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker-short-2 h6 {
    font-size: 5px;
    font-weight: 700;
  }
  .locker-num {
    position: absolute;
    top: 9px;
    left: 31px;
    font-weight: 700;
    font-size: 10px;
  }
  .locker-num1 {
    position: absolute;
    top: 3px;
    left: 36px;
    font-weight: 700;
    font-size: 8px;
  }
  .locker-num2 {
    position: absolute;
    top: 65px;
    left: 36px;
    font-weight: 700;
    font-size: 7px;
  }
  .Owl-top {
    margin-top: 100px;
  }
  .product-images-locker-slider img {
    width: 128px;
    height: 131px;
    top: 100px;
    left: 23px;
  }
}

@media (max-width: 360px) {
  .lockers-short img {
    width: 40px !important;
  }
  .product-images-locker img {
    width: 32px;
    height: 39px;
    position: absolute;
    top: 32px;
    left: 7px;
    border-radius: 3px;
    object-fit: cover;
  }
  .product-name-locker {
    width: 41px;
    position: absolute;
    top: 75px;
    left: 4px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker h6 {
    font-size: 4px;
    font-weight: 300;
  }
  .product-images-locker-short img {
    width: 25px !important;
    height: 17px;
    position: absolute;
    top: 16px;
    left: 8px;
    border-radius: 3px;
  }
  .product-name-locker-short {
    width: 60px;
    position: absolute;
    top: 35px;
    left: -3px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker-short h6 {
    font-size: 5px;
    font-weight: 700;
  }
  .product-images-locker-short-2 img {
    width: 27px !important;
    height: 18px;
    position: absolute;
    top: 70px;
    left: 7px;
    border-radius: 3px;
  }
  .product-name-locker-short-2 {
    width: 60px;
    position: absolute;
    top: 90px;
    left: -3px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker-short-2 h6 {
    font-size: 5px;
    font-weight: 700;
  }
  .locker-num {
    position: absolute;
    top: 4px;
    left: 30px;
    font-weight: 700;
    font-size: 10px;
  }
  .locker-num1 {
    position: absolute;
    top: 3px;
    left: 36px;
    font-weight: 700;
    font-size: 8px;
  }
  .locker-num2 {
    position: absolute;
    top: 59px;
    left: 35px;
    font-weight: 700;
    font-size: 7px;
  }
  .Owl-top {
    margin-top: 100px;
  }
  .product-images-locker-slider img {
    width: 128px !important;
    height: 131px;
    top: 100px;
    left: 23px;
  }
}

@media (max-width: 280px) {
  .video-slider video,
  .image-slider img {
    width: 100%;
  }

  #scanner-buttons {
    width: 100%;
    margin-left: 0;
  }
  .scanner-img-body img {
    width: 200px;
    margin-top: 20px;
  }
  .scanner-img-body p {
    font-size: 10px;
  }
  .lockers-short img {
    width: 29px !important;
  }
  .product-images-locker img {
    width: 22px;
    height: 21px;
    position: absolute;
    top: 23px;
    left: 6px;
    border-radius: 3px;
    object-fit: cover;
  }
  .product-name-locker {
    width: 20px;
    position: absolute;
    top: 52px;
    left: 8px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker h6 {
    font-size: 5px;
  }
  .product-images-locker-short img {
    width: 19px !important;
    height: 14px;
    position: absolute;
    top: 10px;
    left: 5px;
    border-radius: 3px;
  }
  .product-name-locker-short {
    width: 60px;
    position: absolute;
    top: 35px;
    left: -3px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker-short h6 {
    font-size: 5px;
    font-weight: 700;
  }
  .product-images-locker-short-2 img {
    width: 18px !important;
    height: 14px;
    position: absolute;
    top: 47px;
    left: 7px;
    border-radius: 3px;
  }
  .product-name-locker-short-2 {
    width: 60px;
    position: absolute;
    top: 62px;
    left: -3px;
    border-radius: 10px;
    text-align: center;
  }
  .product-name-locker-short-2 h6 {
    font-size: 5px;
    font-weight: 700;
  }
  .locker-num {
    position: absolute;
    top: 3px;
    left: 20px;
    font-weight: 700;
    font-size: 7px;
  }
  .locker-num1 {
    position: absolute;
    top: 3px;
    left: 36px;
    font-weight: 700;
    font-size: 8px;
  }
  .locker-num2 {
    position: absolute;
    top: 59px;
    left: 35px;
    font-weight: 700;
    font-size: 7px;
  }
}

#scanner-buttons {
  width: 32%;
  margin-top: 1%;
  margin-left: 68%;
}

.product-counter {
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10.062px;
  border: 1.4px solid #eee;
  background: #fff;
  color: #000;
  font-family: Arial;
  font-size: 20.47px;
  font-style: normal;
  font-weight: 700;
  margin-top: 10px;
}

.requested-product-counter {
  width: 10px;
  padding: 14px 43px;
  justify-content: center;
  align-items: center;
  border-radius: 10.062px;
  border: 1.4px solid #eee;
  background: #fff;
  color: #000;
  font-family: Arial;
  font-size: 20.47px;
  font-style: normal;
  font-weight: 700;
}

.total-row {
  display: flex;
  margin-top: 0.5rem;
  color: #999;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.total-text {
  margin-top: 0.9rem;
  width: 30%;
}

.total-counter {
  display: flex;
  width: 100%;
  height: 39.613px;
  padding: 14px 43px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10.062px;
  border: 1.4px solid #eee;
  background: #fff;
  color: #686666;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-top: 0.2rem;
}

.search-box {
  width: 100%;
  height: 39.613px;
  border-radius: 10.062px;
  border: 1.4px solid #eee;
  background: #fff;
  color: #686666;
  margin-top: 0.7rem;
}

.select-box {
  width: 100%;
  height: 39.613px;
  border-radius: 10.062px;
  border: 1.4px solid #eee;
  background: #fff;
  color: #686666;
  margin-top: 0.3rem;
}

.select-box select {
  border-radius: 10.062px;
  border: 1.4px solid #eee;
  background: #fff;
  color: #8f8989;
}

.description-text {
  color: #999;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-decrement {
  background: #e15753;
}

.btn-increment {
  background: #012269;
  width: 100%;
}
.btn-dollar {
  background: #e3be18;
}

.row-data {
  padding: 12px 0;
  border-radius: 8px;
  border: 1px solid #eee;
  background: #fff;
  margin-bottom: 10px;
  /* Add a margin-bottom for spacing between rows */
}

.dashboard-table td,
.dashboard-table th {
  padding: 10px;
}

.dashboard-table,
.dashboard-table-view {
  padding: 20px;
}

/* Optional: Add space between cells (and indirectly between rows) */
.table-responsive table {
  border-spacing: 0 10px;
  /* Adjust the second value (vertical spacing) as needed */
}

/* Optional: Add space between rows for mobile responsiveness */

@media (max-width: 992px) {
  .product-images-locker-stringee img {
    width: 62px;
    height: 65px;

    top: 66px;
    left: 15px;
  }
  .product-images-locker-short-stringee-2 img {
    width: 49px;
    height: 35px;
    position: absolute;
    top: 130px;
    left: 13px;
  }
  .product-images-locker-short-stringee img {
    width: 48px;
    height: 36px;
    position: absolute;
    top: 25px;
    left: 15px;
  }
  .product-name-locker-stringee h6 {
    font-size: 9px;
  }
  .product-name-locker-stringee {
    width: 105px;
    height: auto;
    position: absolute;
    top: 141px;
    left: -6px;
  }
  .product-name-locker-stringee-short h6 {
    font-size: 9px;
    font-weight: 500;
  }
  .product-name-locker-stringee-short {
    width: 67px;
    position: absolute;
    top: 67px;
    left: 7px;
  }
  .product-name-locker-stringee-short-2 h6 {
    font-size: 9px;
    font-weight: 500;
  }
  .product-name-locker-stringee-short-2 {
    width: 80px;
    position: absolute;
    top: 169px;
    left: 3px;
  }
  .stringee-locker-num {
    position: absolute;
    top: 10px;
    left: 63px;
  }
  .stringee-locker-num2 {
    position: absolute;
    top: 107px;
    left: 53px;
  }
  .stringee-locker-num1 {
    position: absolute;
    top: 3px;
    left: 53px;
  }
  .local-streaming-side {
    border: 2px solid black;
    width: 335px !important;
    margin-left: -18px !important;
    height: 260px !important;
  }

  .remote-streaming-side {
    border: 2px solid black;
    width: 335px !important;
    height: 260px !important;
  }
}

@media (max-width: 769px) {
  .table-responsive tbody tr {
    margin-bottom: 10px;
  }
  .product-images-locker-stringee img {
    width: 62px;
    height: 65px;

    top: 66px;
    left: 15px;
  }
  .product-images-locker-short-stringee-2 img {
    width: 49px;
    height: 35px;
    position: absolute;
    top: 130px;
    left: 13px;
  }
  .product-images-locker-short-stringee img {
    width: 48px;
    height: 36px;
    position: absolute;
    top: 25px;
    left: 15px;
  }
  .product-name-locker-stringee h6 {
    font-size: 9px;
  }
  .product-name-locker-stringee {
    width: 105px;
    height: auto;
    position: absolute;
    top: 141px;
    left: -6px;
  }
  .product-name-locker-stringee-short h6 {
    font-size: 9px;
    font-weight: 500;
  }
  .product-name-locker-stringee-short {
    width: 67px;
    position: absolute;
    top: 67px;
    left: 7px;
  }
  .product-name-locker-stringee-short-2 h6 {
    font-size: 9px;
    font-weight: 500;
  }
  .product-name-locker-stringee-short-2 {
    width: 80px;
    position: absolute;
    top: 169px;
    left: 3px;
  }
  .stringee-locker-num {
    position: absolute;
    top: 10px;
    left: 63px;
  }
  .stringee-locker-num2 {
    position: absolute;
    top: 107px;
    left: 53px;
  }
  .stringee-locker-num1 {
    position: absolute;
    top: 3px;
    left: 53px;
  }

  .local-streaming-side {
    border: 2px solid black;
    width: 300px !important;
    margin-left: 16px !important;
    height: 230px !important;
  }

  .remote-streaming-side {
    border: 2px solid black;
    width: 300px !important;
    height: 230px !important;
  }
}

@media (max-width: 550px) {
  .local-streaming-side {
    border: 2px solid black;
    width: 200px !important;
    margin-left: -3px !important;
    height: 150px !important;
  }

  .remote-streaming-side {
    border: 2px solid black;
    width: 200px !important;
    height: 150px !important;
  }
}

@media (max-width: 441px) {
  .product-images-locker-stringee img {
    width: 45px;
    height: 46px;
    top: 47px;
    left: 10px;
  }
  .product-images-locker-short-stringee img {
    width: 35px;
    height: 28px;
    position: absolute;
    top: 22px;
    left: 11px;
  }
  .product-images-locker-short-stringee-2 img {
    width: 41px;
    height: 28px;
    position: absolute;
    top: 93px;
    left: 7px;
  }
  .stringee-locker-num {
    position: absolute;
    top: 6px;
    left: 45px;
    font-size: 10px;
  }
  .stringee-locker-num1 {
    position: absolute;
    top: 5px;
    left: 38px;
    font-size: 10px;
  }
  .stringee-locker-num2 {
    position: absolute;
    top: 79px;
    left: 39px;
    font-size: 10px;
  }
  .product-name-locker-stringee {
    width: 105px;
    height: auto;
    position: absolute;
    top: 101px;
    left: -19px;
  }
  .product-name-locker-stringee-short {
    width: 64px;
    position: absolute;
    top: 50px;
    left: -1px;
  }
  .product-name-locker-stringee-short-2 {
    width: 80px;
    position: absolute;
    top: 122px;
    left: -9px;
  }
  .local-streaming-side {
    border: 2px solid black;
    width: 160px !important;
    margin-left: -12px !important;
    height: 125px !important;
  }

  .remote-streaming-side {
    border: 2px solid black;
    width: 160px !important;
    height: 125px !important;
  }
}

@media (max-width: 376px) {
  .product-images-locker-stringee img {
    width: 40px;
    height: 38px;
    top: 41px;
    left: 6px;
  }
  .product-name-locker-stringee {
    width: 105px;
    height: auto;
    position: absolute;
    top: 85px;
    left: -22px;
  }
  .stringee-locker-num {
    position: absolute;
    top: 6px;
    left: 37px;
    font-size: 9px;
  }
  .product-images-locker-short-stringee img {
    width: 30px;
    height: 22px;
    position: absolute;
    top: 16px;
    left: 8px;
  }
  .product-images-locker-short-stringee-2 img {
    width: 31px;
    height: 22px;
    position: absolute;
    top: 76px;
    left: 7px;
  }
  .stringee-locker-num1 {
    position: absolute;
    top: 3px;
    left: 33px;
    font-size: 9px;
  }
  .stringee-locker-num2 {
    position: absolute;
    top: 63px;
    left: 31px;
    font-size: 9px;
  }
  .product-name-locker-stringee-short {
    width: 64px;
    position: absolute;
    top: 40px;
    left: -8px;
  }
  .product-name-locker-stringee-short-2 {
    width: 80px;
    position: absolute;
    top: 100px;
    left: -13px;
  }
  .product-name-locker-stringee h6 {
    font-size: 6px;
  }
  .product-name-locker-stringee-short h6 {
    font-size: 6px;
    font-weight: 500;
  }
  .product-name-locker-stringee-short-2 h6 {
    font-size: 7px;
    font-weight: 500;
  }
  .local-streaming-side {
    border: 2px solid black;
    width: 140px !important;
    margin-left: -12px !important;
    height: 115px !important;
  }

  .remote-streaming-side {
    border: 2px solid black;
    width: 140px !important;
    height: 115px !important;
  }
}
.site-list-text {
  min-width: 81px;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  bottom: 1px;
  border-radius: 42px;
  background: #e6e6f4;
  color: #adadad;
  font-family: Arial;
  font-size: 12.727px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.site-list-text.active {
  background: #012269;
  /* Active color */
  color: #fff;
  font-family: Arial;
  font-size: 12.727px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.qrcode-content {
  width: 100%;
  height: 100vh;
  background-color: #8f8989;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-code-scanning {
  width: 400px;
  height: 400px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.qr-code-scanning img {
  width: 250px;
}

.truncate {
  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;
}

.locker-slide {
  overflow: auto;
}

.otp-input-container {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 25px;
}
.otp-input {
  width: 60px;
  height: 50px;
  margin: 0 5px;
  font-size: 40px;
  text-align: center;
  color: #012267;
  border: none;
  border-bottom: 3px solid #012267;
}
.modal-content {
  border-radius: 10px !important;
}
.selected-locker {
  transform: scale(1.2);
}

/* >>>>>>>>>>>>>>>|| Stringee Video Call ||<<<<<<<<<<<<<<< */

.user-video-screens {
  width: 330px;
  height: 340px;
  position: relative;
  margin-top: 3%;
  border: 2px solid black;
  margin-right: 85px;
}
.admin-video-screens {
  width: 300px;
  height: 250px;
}

.table-data {
  width: 259px;
  height: auto;
  position: relative;
  margin-right: 5px;
}

.ReactModalPortal {
  position: absolute !important;
  z-index: 9999999 !important;
}
.ReactModal__Content {
  outline: none;
}

/* /////Cabinets////// */
.dot {
  position: absolute;
  top: 15px;
  left: 18px;
  font-size: 20px;
  font-weight: 700;
}

.local-streaming-side {
  border: 2px solid black;
  width: 460px;
  margin-left: 27px;
  height: 347px;
}

.remote-streaming-side {
  border: 2px solid black;
  width: 460px;
  height: 347px;
}
