/* body {
  direction: ltr
}

.owl-carousel .owl-stage {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.owl-carousel .owl-item .item {
  width: 120px;
  min-height: 120px;

}

.owl-carousel .owl-item.active.center .item {
  width: 160px;
  height: 200px;

}
 */

.locker-sliders-container {
  margin-top: 40px;
}

.slick-current {
  transform: scale(1.6);
  position: relative;
  z-index: 1;
  margin-left: 40px;
  margin-right: 15px;
}

.slick-list {
  overflow: visible;
}
.slick-next {
  right: -100px;
}
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  /* top: 45%; */
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -800%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev {
  position: absolute;
  /* top: 45%; */
  z-index: 99999;
  left: -120px;
  transform: translate(0, -800%);
}
.locker-content button {
  display: none;
}

.slick-current .locker-content button {
  display: inline-block; /* or block, depending on your layout */
}
.locker-content a {
  display: none;
}

.slick-current .locker-content a {
  display: inline-block; /* or block, depending on your layout */
}
.locker-content p {
  display: none;
}

.slick-current .locker-content p {
  display: inline-block; /* or block, depending on your layout */
}

.slick-slide {
  transition: transform 0.2s ease-in-out;
}
.slick-next:before {
  font-size: 30px;
}
.slick-prev:before {
  font-size: 30px;
}

@media (min-width: 1600px) {
  .slick-prev {
    left: -175px;
  }
  .slick-next {
    right: -160px;
  }
}

@media (max-width: 992px) {
  .add-to-cart {
    margin-top: 90px;
  }
  .locker-sliders-container {
    margin-top: 180px;
  }
  .slick-next {
    right: -85px;
  }
  .slick-prev {
    left: -75px;
  }
  .slick-current {
    margin-left: 38px;
    margin-right: 34px;
  }
}
@media (max-width: 820px) {
  .locker-sliders-container {
    margin-top: 250px;
  }
  .slick-next {
    right: -88px;
  }
  .slick-prev {
    left: -87px;
  }
  .slick-current {
    margin-left: 45px;
    margin-right: 30px;
  }
}

@media (max-width: 768px) {
  .add-to-cart {
    margin-top: 90px;
  }
  .locker-sliders-container {
    margin-top: 200px;
  }
  .slick-next {
    right: -58px;
  }
  .slick-prev {
    left: -63px;
  }
  .slick-current {
    margin-left: 40px;
    margin-right: 38px;
  }
  .slick-next:before {
    font-size: 25px;
  }
  .slick-prev:before {
    font-size: 25px;
  }
}
@media (max-width: 540px) {
  .locker-sliders-container {
    margin-top: 80px;
  }
  .slick-current {
    transform: scale(1.4);
    margin-left: 120px;
    margin-right: -30px;
  }
  .slick-next {
    right: -38px;
  }
  .slick-prev {
    left: -37px;
  }
}

@media (max-width: 433px) {
  .locker-sliders-container {
    margin-top: 80px;
  }
  .slick-current {
    transform: scale(1.4);
    margin-left: 30px;
    margin-right: 8px;
  }
  .slick-next {
    right: -38px;
  }
  .slick-prev {
    left: -37px;
  }
}

@media (max-width: 392px) {
  .add-to-cart {
    margin-top: 80px;
  }
  .locker-sliders-container {
    margin-top: 20px;
  }
  .slick-current {
    transform: scale(1.4);
    margin-left: 18px;
    margin-right: 10px;
  }
  .slick-next {
    right: -28px;
  }
  .slick-prev {
    left: -33px;
  }
}
@media (max-width: 360px) {
  .slick-next {
    right: -28px;
  }
  .slick-prev {
    left: -33px;
  }
}
@media (max-width: 280px) {
  .locker-sliders-container {
    margin-top: 80px;
  }
  .slick-current {
    transform: scale(1.4);
    margin-left: 20px;
    margin-right: 20px;
  }
  .slick-next {
    right: -38px;
  }
  .slick-prev {
    left: -37px;
  }
}
