.sidebar_wrap ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.pickup_order_heading2 {
  width: 100% !important;
  height: 100% !important;
  background: #012269 !important;
  border-top-left-radius: 13px !important;
  border-top-right-radius: 13px !important;
  color: white !important;
  padding: 30px !important;
}
.locker_label {
  color: rgb(9, 9, 125) !important;
  font-size: 15px !important;
  font-weight: 700 !important;
}
.locker_label_value {
  font-size: 13px !important;
  font-weight: 700 !important;
}
